import { lazy, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

import MainLayout from '../layouts/main';

const RoutedPage = (Component) => (props) =>
  (
    <Suspense fallback={<div style={{ minHeight: '100vh' }} />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout><LandingPage/></MainLayout>,
    },
    {
      path: '/privacy',
      element: <MainLayout><PrivacyPage/></MainLayout>,
    },
    {
      path: '/terms',
      element: <MainLayout><TermsPage/></MainLayout>,
    },
    {
      path: '/release-notes',
      element: <MainLayout><ReleaseNotesPage/></MainLayout>,
    },
    {
      path: '/auth/reset-password',
      element: <MainLayout><ResetPasswordPage/></MainLayout>,
    },
    {
      path: '/auth/apple-sso',
      element: <MainLayout><AppleSsoSignInPage/></MainLayout>,
    },
    {
      path: '/auth/strava-auth',
      element: <MainLayout><StravaRedirectPage/></MainLayout>,
    },
    {
      path: '/auth/ms-sso',
      element: <MainLayout><MsSsoSignInPage/></MainLayout>,
    },
    {
      path: '/subscribe',
      element: <MainLayout><SubscribePage/></MainLayout>,
    },
    {
      path: '/support',
      element: <MainLayout><SupportPage/></MainLayout>,
    },
    {
      path: '/features',
      element: <MainLayout><FeaturesPage/></MainLayout>,
    },
    {
      path: '/blog/:permalink',
      element: <MainLayout><BlogPostPage/></MainLayout>,
    },
    {
      path: '/blog',
      element: <MainLayout><BlogPage/></MainLayout>,
    },
    {
      path: '/getapp',
      element: <MainLayout><GetAppPage/></MainLayout>,
    },
    { path: '*', element: <NotFound /> }
  ]);
}

const LandingPage = RoutedPage(lazy(() => import('../pages/LandingPage')));
const PrivacyPage = RoutedPage(lazy(() => import('../pages/PrivacyPage')));
const TermsPage = RoutedPage(lazy(() => import('../pages/TermsPage')));
const ReleaseNotesPage = RoutedPage(lazy(() => import('../pages/PageReleaseNotes')));
const AppleSsoSignInPage = RoutedPage(lazy(() => import('../pages/AppleSsoSignInPage')));
const MsSsoSignInPage = RoutedPage(lazy(() => import('../pages/MsSsoSignInPage')));
const StravaRedirectPage = RoutedPage(lazy(() => import('../pages/StravaRedirectPage')));
const ResetPasswordPage = RoutedPage(lazy(() => import('../pages/ResetPasswordPage')));
const SubscribePage = RoutedPage(lazy(() => import('../pages/SubscribePage')));
const SupportPage = RoutedPage(lazy(() => import('../pages/SupportPage')));
const FeaturesPage = RoutedPage(lazy(() => import('../pages/FeaturesPage')));
const GetAppPage = RoutedPage(lazy(() => import('../pages/GetAppPage')));
const BlogPage = RoutedPage(lazy(() => import('../pages/blog/BlogPostListPage')));
const BlogPostPage = RoutedPage(lazy(() => import('../pages/blog/BlogPostPage')));
const NotFound = RoutedPage(lazy(() => import('../pages/Page404')));
